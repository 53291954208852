import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, ContactForm } from "page_components/career"

const Career = ({ data, location }) => {
  const title = "Kariera"
  const allCareer = data.allWpCareer.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Dołącz do nas!",
        description:
          "Najlepsze zespoły tworzą najlepsi ludzie. Dołącz do nas i buduj z nami lepsze jutro branży, miejsca pracy i rynku mieszkaniowego w Polsce!",
      }}
      rmLangSwitcher
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content allCareer={allCareer} />
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCareer(filter: { language: { code: { eq: PL } } }) {
      nodes {
        id
        title
        uri
        slug
      }
    }
  }
`

export default Career
